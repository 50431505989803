import React, { Component } from "react";

import "./Lieder1Data.css";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap/dist/css/bootstrap.min.css"; // neu

import "datatables.net-searchpanes";
import "./searchPanes.css";
import "datatables.net-select";
import "./select.css";
import "datatables.net-buttons";

export class Tbl1Func extends Component {
  componentDidMount() {
    //initialize datatable

    // console.log(this.props.data);
    var table = $("#example").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/de-DE.json",
        // url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
      },
      data: this.props.data,
      order: [[2, "asc"]],
      //buttons: ["searchPanes"],
      //dom: "BfrtipP",

      dom: "BlfrtipP",

      columns: [
        {
          className: "details-control",
          orderable: false,
          data: null,
          defaultContent: "",
        },
        { data: "Nummer" },
        { data: "Titel" },
        { data: "Komponist" },
        { data: "Text" },
        { data: "Adwords" },
        { data: "Translation" },
        { data: "Language" },
        { data: "Type" },
      ],

      columnDefs: [
        {
          className: "dtr-control",
          title: "Details",
          orderable: false,
          targets: 0,
        },

        {
          title: "Nummer",
          targets: 1,
        },
        {
          title: "Titel",
          targets: 2,
        },
        {
          title: "Komponist",
          targets: 3,
        },
        {
          title: "Texter",
          targets: 4,
        },
        {
          title: "Adwords",
          targets: 5,
          visible: false,
        },
        {
          title: "Übersetzung",
          targets: 6,
          visible: false,
        },
        {
          searchPanes: {
            show: true,
          },
          title: "Sprache",
          targets: 7,
          visible: false,
        },
        {
          searchPanes: {
            show: true,
          },
          title: "Type",
          targets: 8,
          visible: false,
        },
      ],
    });

    function getPDF(titel, original) {
      var url = "https://cmgt.fhco.ch/getpdf_data.php";

      var ip_address = localStorage.getItem("cmgt_ip_address");
      var mail = localStorage.getItem("cmgt_mail");
      var data = "";
      data += "<td><a href=" + url;
      data += "?titel=" + titel;
      data += "&mail=" + mail + "&ipaddress=" + ip_address;
      data += "&original=" + original;
      data += ">PDF" + "</a></td>";
      return data;
      // xpdf += ">PDF" + "</a></td>";
      //mail = "xxxxxxxxxxxxxxx";  // später entfernen
      // if (mail === null || mail === "undefined") {
      //   alert("Bitte zuerst Anmelden ausführen (für Berechtigung)");
      // } else {
      //   //window.open(url + "?titel=" + titel + ';' + mail + ';' + ip_address + ';;');
      //   window.open(
      //     url + "?titel=" + titel + "&mail=" + mail + "&ipaddress=" + ip_address
      //   );
      // }
    }
    function get_originalpdf(File_Name) {
      var url = "https://cmgt.fhco.ch/get_original_details1.php";

      var x = "";
      var xdata = File_Name;
      //alert( xdata);
      if (xdata > "") {
        $.ajax({
          url: url,
          data: { action: xdata },
          async: false,
          type: "POST",
          //contentType: 'application/json',
          success: function (data) {
            var chars = data["data"];

            for (var i = 0, len = chars.length; i < len; i++) {
              if (chars[i].CD_ID !== "") {
                x += "<tr>";
                x += "<td>..." + chars[i].Text + "</td>";
                x += getPDF(chars[i].CD_ID, chars[i].PDF);
                x += "</tr>";
              }
            }
          },

          error: function (request, error) {
            // This callback function will trigger on unsuccessful action
            //alert('Network error has occurred please try again!');
            x = ""; // reset, no entries
          },
        });
      }
      console.log(x);
      return x;
    }

    function get_mspdf(File_Name) {
      var url = "https://cmgt.fhco.ch/get_ms_details1.php";
      var x = "";
      var xdata = File_Name;
      if (xdata > "") {
        $.ajax({
          url: url,
          data: { action: xdata },
          async: false,
          type: "POST",
          //contentType: 'application/json',
          success: function (data) {
            var chars = data["data"];
            for (var i = 0, len = chars.length; i < len; i++) {
              x += "<tr>";
              x += "<td>..." + chars[i].Text + "</td>";
              if (chars[i].CD_ID !== "") {
                if (chars[i].CD_ID !== null) {
                  x += getPDF(chars[i].CD_ID, chars[i].PDF);
                }
              }
              x += "</tr>";
            }
          },

          error: function (request, error) {
            // This callback function will trigger on unsuccessful action
            //alert('Network error has occurred please try again!');
            x = ""; // reset, no entries
          },
        });
      }
      console.log(x);
      return x;
    }
    //........................
    /* Formatting function for row details - modify as you need */
    function format(d) {
      // `d` is the original data object for the row (datatable)

      var xpdf = "";
      if (d.File_Name !== "") {
        xpdf = "<tr><td>Noten zum Lied (aus Musescore):</td>";
        if (d.CD_ID_File_Name !== "") {
          xpdf += getPDF(d.CD_ID_File_Name, d.File_Name);
        }
        xpdf += "</tr>";
      }
      var xTranslation = "";
      if (d.Translation > "") {
        xTranslation = "<tr><td>Übersetzung des Lieds:</td>";
        if (d.CD_ID_Translation !== "") {
          xTranslation += getPDF(d.CD_ID_Translation, d.Translation);
        }
        xTranslation += "</tr>";
      }

      var xOriginal = "";
      var url = "";
      if (d.Original > "") {
        xOriginal =
          "<tr><td>Original/Scan des Lieds: <br>(nur für internen Gebrauch)</td>";
        if (d.CD_ID_Original !== "") {
          xOriginal += getPDF(d.CD_ID_Original, d.Original);
        }
        xOriginal += "</tr>";
        var xorignalpdf = get_originalpdf(d.File_Name);
        xOriginal += xorignalpdf;
      }

      var xmspdf = get_mspdf(d.File_Name);

      url = "https://cmgt.fhco.ch/get_audio_details1.php";

      var xAudio = "";
      var xdata = d.File_Name;
      if (xdata > "") {
        $.ajax({
          url: url,
          data: { action: xdata },
          async: false,
          type: "POST",
          //contentType: 'application/json',
          success: function (data) {
            var chars = data["data"];
            if (chars.length > 0) {
              xAudio += "<tr>" + "<td>Audio</td>";
            }
            for (var i = 0, len = chars.length; i < len; i++) {
              if (i > 0) {
                xAudio += "<tr>"; // begin
                xAudio += "<td></td>";
              }
              xAudio += "<td>" + chars[i].Text + "</td>";
              xAudio +=
                '<td><a href="' + chars[i].Audio + '">' + "Hören" + "</td>";
              //xAudio += '<td><a href="' + chars[i].Audio + '">' + '<img src="./play_logo.png"  alt="Play" style="width:50%">' + '</td>';
              xAudio += "</tr>"; // end
            }
          },

          error: function (request, error) {
            // This callback function will trigger on unsuccessful action
            //alert('Network error has occurred please try again!');
            xAudio = ""; // reset, no entries
          },
        });
      }

      var x =
        '<h5><table style="width:100%">' +
        '<col style="width:30%">' +
        '<col style="width:60%">' +
        '<col style="width:10%">' +
        xpdf +
        xmspdf +
        xTranslation +
        xOriginal +
        xAudio +
        "</table></h5>";
      //alert(x);
      return x;
    }

    //........................

    $("#example tbody").on("click", "td.details-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        console.log(row.data);
        // Open this row
        //row.child( '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"><tr><td>Full name:</td><td>Ajay</td></tr></table>' ).show();
        row.child(format(row.data())).show();
        //row.child(getChildRow(row.data())).show();
        tr.addClass("shown");
      }
    });
  }
  render() {
    //Datatable HTML
    return (
      <div className="MainDiv">
        <div className="jumbotron text-center">{/* <h3>Lieder</h3> */}</div>

        <div className="container">
          <div>
            <table
              id="example"
              className="display"
              width="100%"
              ref={(el) => (this.el = el)}
            ></table>
          </div>
        </div>
      </div>
    );
  }
}
export default Tbl1Func;
