import { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../store/auth-context";
import classes from "./AuthForm.module.css";

const AuthForm = () => {
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const authCtx = useContext(AuthContext);

  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredEmail = emailInputRef.current.value.toLowerCase();
    // const enteredPassword = passwordInputRef.current.value;

    // optional: Add validation

    let url;

    if (isLogin) {
      url =
        // 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCx6FSPwcJy-agoVUaEEd9jsy2BV9cuc7Y';
        "https://cmgt.fhco.ch//logon_react.php";
    } else {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCx6FSPwcJy-agoVUaEEd9jsy2BV9cuc7Y";
    }

    setIsLoading(true);
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: enteredEmail,
        // password: enteredPassword,
        returnSecureToken: true,
      }),
      // headers: {
      //   "Content-Type": "application/json",
      //   //"Content-Type": "Access-Control-Allow-Origin: *",
      // },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = "Authentication failed!";
            // if (data && data.error && data.error.message) {
            //   errorMessage = data.error.message;
            // }

            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        const expirationTime = new Date(
          new Date().getTime() + +data.expiresIn * 1000
        );
        console.log(data);
        // console.log(data.idToken);
        // console.log(data.expiresIn);
        // console.log(data);
        // console.log(expirationTime.toISOString());
        authCtx.login(
          data.idToken,
          expirationTime.toISOString(),
          data.email,
          data.ipdec
        );
        localStorage.setItem("cmgt_mail", enteredEmail);

        navigate("/");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <section className={classes.auth}>
      <h1>{isLogin ? "Login" : "Sign Up"}</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor="email">Your Email</label>
          <input type="email" id="email" required ref={emailInputRef} />
        </div>
        {/* <div className={classes.control}>
          <label htmlFor="password">Your Password</label>
          <input
            type="password"
            id="password"
            required
            ref={passwordInputRef}
          />
        </div> */}
        <div className={classes.actions}>
          {!isLoading && (
            <button>{isLogin ? "Login" : "Create Account"}</button>
          )}
          {isLoading && <p>Sending request...</p>}
          <button
            type="button"
            className={classes.toggle}
            onClick={switchAuthModeHandler}
          >
            {/* {isLogin ? 'Create new account' : 'Login with existing account'} */}
          </button>
        </div>
      </form>
    </section>
  );
};

export default AuthForm;
