import React, { Component } from "react";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap/dist/css/bootstrap.min.css"; // neu


export class UpdateFunc extends Component {
  componentDidMount() {
    //initialize datatable

   $("#example").DataTable({
      language: {
        url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
      },
      data: this.props.data,
      order: [[0, "desc"]],

      columns: [
        { data: "Datum" },
        { data: "Text" },
      ],

      columnDefs: [
        {
          title: "Datum",
          targets: 0,
        },
        {
          title: "Text",
          targets: 1,
        },
      ],
    });

  }
  render() {
    //Datatable HTML
    return (
      <div className="MainDiv">
        <div className="jumbotron text-center">
          {/* <h3>Lieder</h3> */}
        </div>

        <div className="container">
          <div>
            <table
              id="example"
              className="display"
              width="100%"
              ref={(el) => (this.el = el)}
            ></table>
          </div>
        </div>
      </div>
    );
  }
}
export default UpdateFunc;
