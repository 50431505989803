import classes from "./StartingPageContent.module.css";

const StartingPageContent = () => {
  return (
    <section className={classes.starting}>
      <h1>Bündner Gemischter Chor Zürich</h1>
      Diese App soll es erleichtern, nach Liedern in unserem Liederarchiv zu
      suchen. Im Suchfeld kann nach beliebigen Wörtern gesucht werden. Das
      können Komponisten, Texter, Titel eines Liedes oder sogar nach Liedtext
      (meistens der Anfang eines Liedes) gesucht werden. Am besten probiert man
      es einfach etwas aus!<br></br>
      Der Zugang zu den Liedtexten und -Noten als PDF ist &nbsp;
      <a
        href="http://www.admin.ch/opc/de/classified-compilation/19920251/index.html#id-2-5"
        rel="external"
      >
        aus urheber-rechtlichen Gründen
      </a>
      &nbsp; nur Chormitgliedern erlaubt. Gib unter 'Login' deine eMail-Adresse
      an, und du hast Zugriff zu den Liedtexten und -Noten. Lieder mit der
      Seitenzahl 999 sind reine Kopien und noch nicht digitalisiert. Diese
      werden nach und nach erfasst.
    </section>
  );
};

export default StartingPageContent;
