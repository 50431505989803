import React from "react";
import { useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import Lieder1Page from "./pages/Lieder1Page";
import UpdatePage from "./pages/UpdatePage";
import AuthContext from "./store/auth-context";

function App() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(authCtx);
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        {!authCtx.isLoggedIn && (
          <Route path="/auth" element={<AuthPage />}></Route>
        )}
        <Route
          path="/update"
          element={
            authCtx.isLoggedIn ? <UpdatePage /> : <Navigate to="/auth" />
          }
        />
        <Route
          path="/lieder1"
          element={
            authCtx.isLoggedIn ? <Lieder1Page /> : <Navigate to="/auth" />
          }
        />
        <Route path="*" element={() => <Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

export default App;
