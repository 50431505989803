import { useContext } from "react";
import { NavLink } from "react-router-dom";

import AuthContext from "../../store/auth-context";
import classes from "./MainNavigation.module.css";

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  console.log(authCtx);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
    // optional: redirect the user
  };

  return (
    <header className={classes.header}>
      <NavLink to="/">
        {/* <div className={classes.logo}>CMGT</div> */}
        <div>
          CMGT
          <img src="../cmgt_logo.jpg" alt="CMGT Logo" width="160" height="80" />
        </div>
      </NavLink>
      <nav>
        <ul>
          {!isLoggedIn && (
            <li>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.active : ""
                }
                to="/auth"
              >
                Login
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.active : ""
                }
                to="/lieder1"
              >
                Lieder
              </NavLink>
            </li>
          )}
          {/* {isLoggedIn && (
            <li>
              <NavLink className={(navData) => navData.isActive ? classes.active : ''} to="/lieder">
                Lieder
              </NavLink>
            </li>
          )} */}
          {/* {isLoggedIn && (
            <li>
              <NavLink className={(navData) => navData.isActive ? classes.active : ''} to="/profile">
                Profile
              </NavLink>
            </li>
          )} */}
          {isLoggedIn && (
            <li>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.active : ""
                }
                to="/update"
              >
                Änderungen
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={logoutHandler}>Logout</button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
